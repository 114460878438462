const state = {
  deviceBenchEditInfoHeaders: [
    {
      title: "최종접속일시",
      value: "date",
      align: "center",
    },
    {
      title: "담당자",
      value: "name",
      align: "center",
    },
    {
      title: "파트너",
      value: "partner",
      align: "center",
    },
    {
      title: "고객명",
      value: "customer",
      align: "center",
    },
    {
      title: "정류장이름",
      value: "location1",
      align: "center",
    },
    {
      title: "쉘터번호",
      value: "location2",
      align: "center",
    },
    {
      title: "주소",
      value: "address",
      align: "center",
    },
    {
      title: "그룹",
      value: "group",
      align: "center",
    },
  ],
  deviceBedEditInfoHeaders: [
    {
      title: "최종접속일시",
      value: "date",
      align: "center",
    },
    {
      title: "파트너",
      value: "partner",
      align: "center",
    },
    {
      title: "고객명",
      value: "customer",
      align: "center",
    },
    {
      title: "방이름",
      value: "location1",
      align: "center",
    },
    {
      title: "현장명",
      value: "location2",
      align: "center",
    },
    {
      title: "층수",
      value: "location3",
      align: "center",
    },
    {
      title: "주소",
      value: "address",
      align: "center",
    },
    {
      title: "그룹",
      value: "group",
      align: "center",
    },
  ],
  deviceBenchTempHeaders: [
    {
      title: "시간",
      value: "date",
      align: "center",
    },
    {
      title: "담당자",
      value: "name",
      align: "center",
    },
    {
      title: "전원설정",
      value: "onOff",
      align: "center",
    },
    {
      title: "현재온도설정(℃)",
      value: "targetTemp",
      align: "center",
    },
    {
      title: "외부온도설정(℃)",
      value: "triggerEnvTemp",
      align: "center",
    },
  ],
  deviceBedTempHeaders: [
    {
      title: "시간",
      value: "date",
      align: "center",
    },
    {
      title: "담당자",
      value: "name",
      align: "center",
    },
    {
      title: "전원설정",
      value: "onOff",
      align: "center",
    },
    {
      title: "현재온도설정(℃)",
      value: "targetTemp",
      align: "center",
    },
  ],
  deviceTimeHeaders: [
    {
      title: "시간",
      value: "date",
      align: "center",
    },
    {
      title: "담당자",
      value: "name",
      align: "center",
    },
    {
      title: "타이머",
      value: "scheduleEnable",
      align: "center",
    },

    {
      title: "일요일 정보",
      value: "sun",
      align: "center",
    },
    {
      title: "월요일 정보",
      value: "mon",
      align: "center",
    },
    {
      title: "화요일 정보",
      value: "tue",
      align: "center",
    },
    {
      title: "수요일 정보",
      value: "wed",
      align: "center",
    },
    {
      title: "목요일 정보",
      value: "thu",
      align: "center",
    },
    {
      title: "금요일 정보",
      value: "fri",
      align: "center",
    },
    {
      title: "토요일 정보",
      value: "sat",
      align: "center",
    },
  ],
  // deviceTimeHeaders: [
  //   {
  //     title: "시간",
  //     value: "date",
  //     align: "center",
  //   },
  //   {
  //     title: "담당자",
  //     value: "name",
  //     align: "center",
  //   },
  //   {
  //     title: "타이머",
  //     value: "scheduleEnable",
  //     align: "center",
  //   },

  //   {
  //     title: "일1사용",
  //     value: "sun1",
  //     align: "center",
  //   },
  //   {
  //     title: "일1시작",
  //     value: "sun1time1",
  //     align: "center",
  //   },
  //   {
  //     title: "일1종료",
  //     value: "sun1time2",
  //     align: "center",
  //   },
  //   {
  //     title: "일2사용",
  //     value: "sun2",
  //     align: "center",
  //   },
  //   {
  //     title: "일2시작",
  //     value: "sun2time1",
  //     align: "center",
  //   },
  //   {
  //     title: "일2종료",
  //     value: "sun2time2",
  //     align: "center",
  //   },
  //   {
  //     title: "일3사용",
  //     value: "sun3",
  //     align: "center",
  //   },
  //   {
  //     title: "일3시작",
  //     value: "sun3time1",
  //     align: "center",
  //   },
  //   {
  //     title: "일3종료",
  //     value: "sun3time2",
  //     align: "center",
  //   },
  //   {
  //     title: "월1사용",
  //     value: "mon1",
  //     align: "center",
  //   },
  //   {
  //     title: "월1시작",
  //     value: "mon1time1",
  //     align: "center",
  //   },
  //   {
  //     title: "월1종료",
  //     value: "mon1time2",
  //     align: "center",
  //   },
  //   {
  //     title: "월2사용",
  //     value: "mon2",
  //     align: "center",
  //   },
  //   {
  //     title: "월2시작",
  //     value: "mon2time1",
  //     align: "center",
  //   },
  //   {
  //     title: "월2종료",
  //     value: "mon2time2",
  //     align: "center",
  //   },
  //   {
  //     title: "월3사용",
  //     value: "mon3",
  //     align: "center",
  //   },
  //   {
  //     title: "월3시작",
  //     value: "mon3time1",
  //     align: "center",
  //   },
  //   {
  //     title: "월3종료",
  //     value: "mon3time2",
  //     align: "center",
  //   },

  //   {
  //     title: "화1사용",
  //     value: "tue1",
  //     align: "center",
  //   },
  //   {
  //     title: "화1시작",
  //     value: "tue1time1",
  //     align: "center",
  //   },
  //   {
  //     title: "화1종료",
  //     value: "tue1time2",
  //     align: "center",
  //   },
  //   {
  //     title: "화2사용",
  //     value: "tue2",
  //     align: "center",
  //   },
  //   {
  //     title: "화2시작",
  //     value: "tue2time1",
  //     align: "center",
  //   },
  //   {
  //     title: "화2종료",
  //     value: "tue2time2",
  //     align: "center",
  //   },
  //   {
  //     title: "화3사용",
  //     value: "tue3",
  //     align: "center",
  //   },
  //   {
  //     title: "화3시작",
  //     value: "tue3time1",
  //     align: "center",
  //   },
  //   {
  //     title: "화3종료",
  //     value: "tue3time2",
  //     align: "center",
  //   },

  //   {
  //     title: "수1사용",
  //     value: "wed1",
  //     align: "center",
  //   },
  //   {
  //     title: "수1시작",
  //     value: "wed1time1",
  //     align: "center",
  //   },
  //   {
  //     title: "수1종료",
  //     value: "wed1time2",
  //     align: "center",
  //   },
  //   {
  //     title: "수2사용",
  //     value: "wed2",
  //     align: "center",
  //   },
  //   {
  //     title: "수2시작",
  //     value: "wed2time1",
  //     align: "center",
  //   },
  //   {
  //     title: "수2종료",
  //     value: "wed2time2",
  //     align: "center",
  //   },
  //   {
  //     title: "수3사용",
  //     value: "wed3",
  //     align: "center",
  //   },
  //   {
  //     title: "수3시작",
  //     value: "wed3time1",
  //     align: "center",
  //   },
  //   {
  //     title: "수3종료",
  //     value: "wed3time2",
  //     align: "center",
  //   },

  //   {
  //     title: "목1사용",
  //     value: "thu1",
  //     align: "center",
  //   },
  //   {
  //     title: "목1시작",
  //     value: "thu1time1",
  //     align: "center",
  //   },
  //   {
  //     title: "목1종료",
  //     value: "thu1time2",
  //     align: "center",
  //   },
  //   {
  //     title: "목2사용",
  //     value: "thu2",
  //     align: "center",
  //   },
  //   {
  //     title: "목2시작",
  //     value: "thu2time1",
  //     align: "center",
  //   },
  //   {
  //     title: "목2종료",
  //     value: "thu2time2",
  //     align: "center",
  //   },
  //   {
  //     title: "목3사용",
  //     value: "thu3",
  //     align: "center",
  //   },
  //   {
  //     title: "목3시작",
  //     value: "thu3time1",
  //     align: "center",
  //   },
  //   {
  //     title: "목3종료",
  //     value: "thu3time2",
  //     align: "center",
  //   },

  //   {
  //     title: "금1사용",
  //     value: "fri1",
  //     align: "center",
  //   },
  //   {
  //     title: "금1시작",
  //     value: "fri1time1",
  //     align: "center",
  //   },
  //   {
  //     title: "금1종료",
  //     value: "fri1time2",
  //     align: "center",
  //   },
  //   {
  //     title: "금2사용",
  //     value: "fri2",
  //     align: "center",
  //   },
  //   {
  //     title: "금2시작",
  //     value: "fri2time1",
  //     align: "center",
  //   },
  //   {
  //     title: "금2종료",
  //     value: "fri2time2",
  //     align: "center",
  //   },
  //   {
  //     title: "금3사용",
  //     value: "fri3",
  //     align: "center",
  //   },
  //   {
  //     title: "금3시작",
  //     value: "fri3time1",
  //     align: "center",
  //   },
  //   {
  //     title: "금3종료",
  //     value: "fri3time2",
  //     align: "center",
  //   },

  //   {
  //     title: "토1사용",
  //     value: "sat1",
  //     align: "center",
  //   },
  //   {
  //     title: "토1시작",
  //     value: "sat1time1",
  //     align: "center",
  //   },
  //   {
  //     title: "토1종료",
  //     value: "sat1time2",
  //     align: "center",
  //   },
  //   {
  //     title: "토2사용",
  //     value: "sat2",
  //     align: "center",
  //   },
  //   {
  //     title: "토2시작",
  //     value: "sat2time1",
  //     align: "center",
  //   },
  //   {
  //     title: "토2종료",
  //     value: "sat2time2",
  //     align: "center",
  //   },
  //   {
  //     title: "토3사용",
  //     value: "sat3",
  //     align: "center",
  //   },
  //   {
  //     title: "토3시작",
  //     value: "sat3time1",
  //     align: "center",
  //   },
  //   {
  //     title: "토3종료",
  //     value: "sat3time2",
  //     align: "center",
  //   },
  // ],
  deviceNetworkHeaders: [
    {
      title: "시간",
      value: "date",
      align: "center",
    },
    {
      title: "담당자",
      value: "name",
      align: "center",
    },
    {
      title: "통신주기 (초)",
      value: "commInterval",
      align: "center",
    },
    {
      title: "타임존 (UTC)",
      value: "timezone",
      align: "center",
    },
    {
      title: "Wifi SSID",
      value: "wifiSSID",
      align: "center",
    },
    {
      title: "Wifi 비밀번호",
      value: "wifiPassword",
      align: "center",
    },
  ],
  deviceConfigHeaders: [
    {
      title: "시간",
      value: "date",
      align: "center",
    },
    {
      title: "변경",
      value: "name",
      align: "center",
    },
    {
      title: "화면잠금",
      value: "screenLock",
      align: "center",
    },
    {
      title: "외부온도표시",
      value: "dispTemp",
      align: "center",
    },
    {
      title: "가동온도설정",
      value: "targetTempSensitivity",
      align: "center",
    },
    {
      title: "온도급변경계값설정",
      value: "rapidTempThreshold",
      align: "center",
    },
    {
      title: "재부팅 설정",
      value: "dailyRebootEnable",
      align: "center",
    },
    {
      title: "재부팅 시간",
      value: "dailyRebootTime",
      align: "center",
    },
    {
      title: "주기모드 (분) ON",
      value: "periodOnMin",
      align: "center",
    },
    {
      title: "주기모드 (분) OFF",
      value: "periodOffMin",
      align: "center",
    },
    {
      title: "백라이트 레벨(%) 일반",
      value: "backlightShow",
      align: "center",
    },
    {
      title: "백라이트 레벨(%) 대기",
      value: "backlightIdle",
      align: "center",
    },
    {
      title: "난방온도범위 최소",
      value: "heatLowLimit",
      align: "center",
    },
    {
      title: "난방온도범위 최대",
      value: "heatHighLimit",
      align: "center",
    },
  ],
  deviceRemoteHeaders: [
    {
      title: "시간",
      value: "date",
      align: "center",
    },
    {
      title: "담당자",
      value: "name",
      align: "center",
    },
    {
      title: "장치제어",
      value: "remote",
      align: "center",
    },
  ],

  deviceBenchEditStateHeaders: [
    {
      title: "변동일시",
      value: "date",
      align: "center",
    },
    {
      title: "운전/정지",
      value: "onOffStatus",
      align: "center",
    },
    {
      title: "난방상태",
      value: "heatOn",
      align: "center",
    },
    {
      title: "현재온도",
      value: "currTemp",
      align: "center",
    },
    {
      title: "외부온도",
      value: "envTemp",
      align: "center",
    },
    {
      title: "급격한 온도 변경",
      value: "rapidTemp",
      align: "center",
    },
    {
      title: "내부온도 미 변경",
      value: "fixedTemp",
      align: "center",
    },
    {
      title: "온도센서단선",
      value: "sensorOpen",
      align: "center",
    },
  ],

  deviceBedEditStateHeaders: [
    {
      title: "변동일시",
      value: "date",
      align: "center",
    },
    {
      title: "운전/정지",
      value: "onOffStatus",
      align: "center",
    },
    {
      title: "난방상태",
      value: "heatOn",
      align: "center",
    },
    {
      title: "현재온도",
      value: "currTemp",
      align: "center",
    },
    {
      title: "급격한 온도 변경",
      value: "rapidTemp",
      align: "center",
    },
    {
      title: "내부온도 미 변경",
      value: "fixedTemp",
      align: "center",
    },
    {
      title: "온도센서단선",
      value: "sensorOpen",
      align: "center",
    },
  ],

  devicePowerHeaders: [
    {
      title: "켜진 시간",
      value: "start",
      align: "center",
    },
    {
      title: "꺼진 시간",
      value: "end",
      align: "center",
    },
    {
      title: "동작시간(초)",
      value: "value",
      align: "center",
    },
  ],
  test: "",
  endState__: null,
};

const getters = {
  test() {
    return state.test;
  },
  endGetters__() {
    return null;
  },
};

const mutations = {
  setTest(state, payload) {
    state.test = payload;
  },
  endMutations__() {},
};

const actions = {
  // actTest({ state, commit, dispatch }, args) {
  //     // console.log('CALL actTest()')
  //     return 'test'
  // },
  actTest() {
    // console.log("CALL actTest()");
    return "test";
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
